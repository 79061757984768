import { useEffect, useState, Dispatch, SetStateAction } from "react";

const useImageHeight = (): [
  number | null | undefined,
  Dispatch<SetStateAction<HTMLDivElement | null>>
] => {
  const [el, setEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (el) {
      setEl(el);
    }
  }, [el]);

  return [el?.clientHeight, setEl];
};

export { useImageHeight };
