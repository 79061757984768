import styled from "@emotion/styled";
import { Heading } from "@otrium/atoms";
import { HyperLink } from "src/atoms/HyperLink";

const StyledProductCarouselInfoText = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    margin-right: ${({ theme }) => theme.space.space32};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-left: ${({ theme }) => theme.space.space16};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-right: ${({ theme }) => theme.space.space16};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: ${({ theme }) => theme.space.space32};
  }
`;

const StyledTitle = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  margin-bottom: ${({ theme }) => theme.space.space8};
  color: ${({ theme }) => theme.colors.tone.black};
  text-transform: none;
  font-family: "CharisSILW";
  line-height: 1.33;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    line-height: 1.25;
  }
`;

const StyledSubtitle = styled(Heading)`
  text-transform: unset;
  letter-spacing: 0.7px;
`;

const StyledLink = styled(HyperLink)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-family: "CharisSILW";

  svg {
    margin-top: 6px;
    margin-left: ${({ theme }) => theme.space.space8};
  }

  div {
    font-size: ${({ theme }) => theme.fontSizes[1]}px;
    font-weight: 600;
    display: flex;
    margin-right: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    align-items: baseline;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    div {
      margin-right: auto;
    }
  }
`;

export {
  StyledProductCarouselInfoText,
  StyledTitle,
  StyledLink,
  StyledSubtitle,
};
