import styled from "@emotion/styled";
import { Flex } from "@otrium/core";

const StyledCarouselHeader = styled(Flex)`
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-right: ${({ theme }) => theme.space.space16};
    margin-left: ${({ theme }) => theme.space.space16};
  }
`;

const StyledTitleWrapper = styled.div`
  svg {
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      display: none;
    }
  }
`;

export { StyledCarouselHeader, StyledTitleWrapper };
