import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass from "swiper/types/swiper-class";
import { useBreakpoints } from "@otrium/core";
import { ArrowBack } from "src/icons/ArrowBack";
import { ArrowNext } from "src/icons/ArrowNext";
import { BrandCardFallback } from "src/molecules/BrandCard";
import ProductCardFallback from "src/molecules/ProductCardSkeleton/ProductCardSkeleton";
import {
  CarouselMarginSize,
  CarouselTemplate,
  StyledButtonBack,
  StyledButtonNext,
  StyledSwiperWrapper,
} from "./Carousel.styled";
import { isClient } from "src/utils/isClient";

interface CarouselProps {
  items: Array<ReactNode>;
  slidesDesktop?: number;
  slidesTablet?: number;
  slidesMobile?: number;
  maxItems?: number;
  marginSize?: CarouselMarginSize;
  marginSizeMobile?: CarouselMarginSize | undefined;
  shouldReset?: boolean;
  isProductCarousel?: boolean;
  imageElementWrapperHeight?: number | null | undefined;
  slideShadowSpace?: number;
  hasVerticalPadding?: boolean;
}

const Carousel: FC<CarouselProps> = ({
  items,
  slidesDesktop,
  slidesTablet,
  slidesMobile,
  maxItems,
  marginSize = "l",
  marginSizeMobile,
  shouldReset,
  imageElementWrapperHeight,
  isProductCarousel = true,
  slideShadowSpace,
  hasVerticalPadding = true,
}) => {
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [hasHorizontalPadding, setHasHorizontalPadding] = useState(true);
  const prevButton = useRef<HTMLButtonElement>(null);
  const nextButton = useRef<HTMLButtonElement>(null);
  const spaceBetweenTabletAndDesktop =
    marginSize === "m" ? 16 : marginSize === "l" ? 24 : 32; // NOTE: "xl"
  const spaceBetweenMobile =
    marginSizeMobile === "m" ? 8 : marginSizeMobile === "l" ? 16 : 24; // NOTE: "xl"

  // the slides takes a property `visibleSlides`
  // this vary per breakpoint
  const { isTablet, isDesktop } = useBreakpoints();
  slideShadowSpace = isDesktop ? slideShadowSpace : undefined;

  const spaceBetween =
    !isDesktop && !isTablet && marginSizeMobile
      ? spaceBetweenMobile
      : spaceBetweenTabletAndDesktop;

  const arrowTopPositionCalculated = (additionalSpace?: number) => {
    if (
      slidesDesktop &&
      imageElementWrapperHeight &&
      items.length > slidesDesktop
    ) {
      return `${imageElementWrapperHeight / 2 + (additionalSpace || 0)}px`;
    }

    return null;
  };

  const arrowTopPosition = arrowTopPositionCalculated(slideShadowSpace);
  // visible slides per breakpoint
  // Desktop -> 3 slides
  // Tablet -> 2 slides
  // Mobile -> 1 slide
  const visibleSlides = isDesktop
    ? slidesDesktop || 3
    : isTablet
    ? slidesTablet || 2
    : slidesMobile || 1;

  const onSwiper = useCallback(
    (swiperInstance: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSwiper(swiperInstance);
    },
    [setSwiper]
  );

  useEffect(() => {
    let id: number | null = null;
    if (shouldReset && swiper) {
      swiper.slideTo(0);
      // the workaround to fix the issue
      // after reset slide items slide buttons sometimes don't render properly
      id = window.setTimeout(() => {
        swiper.navigation.update();
      }, 10);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [shouldReset, swiper, items]);

  const carouselItems =
    maxItems && items.length > maxItems ? items.slice(0, maxItems) : items;

  swiper?.on("slideChange", function () {
    const carouselItemsLength =
      slidesDesktop && carouselItems.length - slidesDesktop;

    if (swiper.activeIndex === carouselItemsLength) {
      setIsLastSlide(true);
    } else {
      setIsLastSlide(false);
    }
  });

  useEffect(() => {
    const handleIsInvisible = () => {
      swiper?.slides.forEach((slide) => {
        if (!slide.classList.contains("swiper-slide-visible")) {
          slide.classList.add("swiper-slide-invisible");
          slide.removeAttribute("aria-current");
        }
      });
      setHasHorizontalPadding(true);
    };
    const handleRemoveInvisible = () => {
      swiper?.slides.forEach((slide) => {
        slide.classList.remove("swiper-slide-invisible");
        slide.setAttribute("aria-current", "true");
      });
      setHasHorizontalPadding(false);
    };

    if (slideShadowSpace) {
      swiper?.on("update", () => {
        handleRemoveInvisible();
      });
      swiper?.on("transitionEnd", () => {
        handleIsInvisible();
        handleRemoveInvisible();
      });
      handleIsInvisible();
    }

    return () => {
      swiper?.off("transitionStart");
      swiper?.off("transitionEnd");
      swiper?.off("sliderMove");
    };
  }, [swiper, slideShadowSpace]);

  return (
    <CarouselTemplate
      data-testid="carousel-template"
      role="listbox"
      aria-label="carousel"
    >
      <StyledSwiperWrapper
        marginSizeMobile={marginSizeMobile}
        isFitInsideContainer={visibleSlides >= carouselItems?.length}
        slideShadowSpace={slideShadowSpace}
        hasHorizontalPadding={hasHorizontalPadding}
        hasVerticalPadding={hasVerticalPadding}
        isClient={isClient}
        data-testid="carousel-swiper-wrapper"
      >
        <Swiper
          initialSlide={0}
          spaceBetween={spaceBetween}
          slidesPerView={visibleSlides}
          slidesPerGroup={Math.floor(visibleSlides)}
          cssMode={!slideShadowSpace} // NOTE: Don't forget to use "smoothscroll-polyfill" package to fix animation on Safari. Check Swiper API
          mousewheel={true}
          onSwiper={onSwiper}
          speed={500}
          navigation={{
            prevEl: prevButton?.current,
            nextEl: nextButton?.current,
          }}
          watchSlidesVisibility={!!slideShadowSpace}
        >
          {carouselItems && carouselItems.length === 0
            ? carouselItems.map((_, index) => (
                <SwiperSlide key={`carousel-card-fallback-${index}`}>
                  {isProductCarousel ? (
                    <ProductCardFallback />
                  ) : (
                    <BrandCardFallback />
                  )}
                </SwiperSlide>
              ))
            : null}

          {carouselItems?.map((item, index) => (
            <SwiperSlide
              key={index}
              role="option"
              aria-label={`product ${index + 1} of ${carouselItems.length}`}
            >
              {item}
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledSwiperWrapper>
      <StyledButtonBack
        ref={prevButton}
        tabIndex={0}
        className={swiper?.activeIndex === 0 ? "swiper-button-disabled" : ""}
        offsetPosition={arrowTopPosition}
        data-testid="carousel-prev-button"
        disabled={!isClient}
      >
        <span className="sr-only">Previous products</span>
        <ArrowBack color="#999" role="presentation" />
      </StyledButtonBack>

      <StyledButtonNext
        tabIndex={0}
        ref={nextButton}
        className={isLastSlide ? "swiper-button-disabled" : ""}
        offsetPosition={arrowTopPosition || "50%"}
        data-testid="carousel-next-button"
        disabled={isLastSlide}
      >
        <span className="sr-only">Next products</span>
        <ArrowNext color="#999" role="presentation" />
      </StyledButtonNext>
    </CarouselTemplate>
  );
};

export default Carousel;
