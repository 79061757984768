import styled from "@emotion/styled";
import BaseButton from "src/atoms/Button/BaseButton";
import { isClient } from "src/utils/isClient";

export type CarouselMarginSize = "xl" | "l" | "m";
const spaceBetweenMobile = (marginSizeMobile: CarouselMarginSize) => {
  switch (marginSizeMobile) {
    case "m":
      return 8;
    case "l":
      return 16;
    default:
      return 24; // NOTE: "xl"
  }
};

const StyledSwiperWrapper = styled.div<{
  marginSizeMobile?: CarouselMarginSize;
  isFitInsideContainer: boolean;
  slideShadowSpace?: number;
  hasHorizontalPadding?: boolean;
  hasVerticalPadding?: boolean;
  isClient?: boolean;
}>`
  ${({ slideShadowSpace, hasHorizontalPadding, hasVerticalPadding }) =>
    slideShadowSpace &&
    `
    margin-left: ${hasHorizontalPadding ? `-${slideShadowSpace}px` : `0`};
    margin-right: ${hasHorizontalPadding ? `-${slideShadowSpace}px` : `0`};
    margin-top: ${hasVerticalPadding ? `-${slideShadowSpace}px` : `0`};
    margin-bottom: ${hasVerticalPadding ? `-${slideShadowSpace}px` : `0`};
    overflow: hidden;
    
    .swiper-slide-visible {
      visibility: visible;
    }
    
    .swiper-slide-invisible {
      visibility: hidden;
    }
    `}

  .swiper-container {
    ${({ slideShadowSpace, hasHorizontalPadding, hasVerticalPadding }) =>
      slideShadowSpace &&
      `
      padding-left: ${hasHorizontalPadding ? `${slideShadowSpace}px` : `0`};
      padding-right: ${hasHorizontalPadding ? `${slideShadowSpace}px` : `0`};
      padding-top: ${hasVerticalPadding ? `${slideShadowSpace}px` : `0`};
      padding-bottom: ${hasVerticalPadding ? `${slideShadowSpace}px` : `0`};
      `}
    overflow: unset;
    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
      z-index: 0;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${({ isFitInsideContainer, theme, marginSizeMobile }) =>
      isFitInsideContainer
        ? `
    padding: 0 ${
      (marginSizeMobile && `${spaceBetweenMobile(marginSizeMobile)}px`) ||
      theme.space.space16
    };
    `
        : `
    .swiper-slide {
      &:first-of-type {
        padding-left: ${theme.space.space16};
      }
      &:last-of-type {
        margin-right: ${
          (marginSizeMobile && `${spaceBetweenMobile(marginSizeMobile)}px`) ||
          theme.space.space16
        };
      }
    }
    `}
  }

  .swiper-button-disabled {
    display: none;
  }
  ${({ isClient, theme }) =>
    !isClient &&
    `.swiper-wrapper {
      overflow: auto;
    }`}

  .swiper-slide {
    width: ${!isClient ? `30%` : `auto`};
    margin-right: ${!isClient ? `24px` : `unset`};

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      width: ${!isClient ? `22%` : `auto`};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      width: ${!isClient ? `15%` : `auto`};
    }
  }
`;

const styledArrow = ({
  theme,
  showInfoText,
}: {
  theme: any;
  showInfoText?: boolean;
}) => `
  position: absolute;
  z-index: 20;
  width: 32px;
  height: 32px;
  background-color: ${theme.colors.tone.white};
  border: 2px solid ${theme.colors.tone.silver};
  display: none;
  cursor: pointer;
  @media screen and (min-width: ${theme.breakpoints[3] as string}) {
    &:not([disabled]) {
      display: block;
    }
  }
  transform: translate(-50%, -50%);
  
  ${
    showInfoText
      ? `&:not([disabled]) {
      @media screen and (min-width: ${theme.breakpoints[1] as string}) {
        display: block;
      }
    }`
      : ""
  }
`;

const StyledButtonBack = styled(BaseButton)<{
  offsetPosition?: string | null;
}>`
  left: 0;
  top: ${({ offsetPosition }) => offsetPosition};
  border-radius: 24px;
  ${styledArrow}
`;

const StyledButtonNext = styled(BaseButton)<{
  offsetPosition?: string | null;
}>`
  left: 100%;
  top: ${({ offsetPosition }) => offsetPosition};
  border-radius: 24px;
  ${styledArrow}
`;

const CarouselTemplate = styled.div`
  position: relative;

  .sr-only {
    display: none;
  }
`;

export {
  CarouselTemplate,
  StyledSwiperWrapper,
  StyledButtonNext,
  StyledButtonBack,
};
